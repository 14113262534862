import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { setLanguage } from "redux-i18n"
import { LANGUAGE } from '../../constants/SettingsKeys';
import { fetchApiOptions } from '../../actions/GlobalsActions';


require('./UserSettings.scss');

class LangSelect extends React.Component {
  constructor(props, context) {
    super(props);

    // Moved here since we need context.
    this.options = [
      { ID: 'en', Name: context.t('english') },
      { ID: 'he', Name: context.t('hebrew') },
      { ID: 'fr', Name: context.t('french') },
      { ID: 'it', Name: context.t('italian') },
    ];

    this.state = {
      currentLang: props.lang,  // Initialize currentLang in the state
    };

  }

  componentDidUpdate(prevProps) {
    // Check if the language has changed
    console.log('this.state.currentLang', this.state.currentLang);
    // if (prevProps.lang !== this.props.lang) {

    if (prevProps.lang !== this.props.lang && this.props.lang !== this.state.currentLang) {
      console.log('this.state.currentLang', this.state.currentLang, prevProps.lang !== this.props.lang, this.props.lang !== this.state.currentLang);

      this.setState({ currentLang: this.props.lang }); // Update state to trigger a re-render
      // this.props.dispatch(fetchOptionsIfNeeded());
    }
  }


  // onChange(lang) {
  //   console.log('onChange runs');
  //   // this.setState({ currentLang: lang });
  //   this.props.dispatch(setLanguage(lang));
  //   localStorage.setItem(LANGUAGE, lang);
  //   this.props.dispatch(fetchOptionsIfNeeded());
  // }

  onChange = async (lang) => {
    try {
      console.log('onChange runs');
      this.props.dispatch(setLanguage(lang));
      localStorage.setItem(LANGUAGE, lang);
      await this.props.dispatch(fetchApiOptions());
      // await this.props.dispatch(fetchOptionsIfNeeded());

    } catch (error) {
      console.error('Error in onChange:', error);
    }
  };

  render() {

    const { lang } = this.props;

    return (
      <div className="lang-select">
        <select onChange={e => this.onChange(e.target.value)}
          value={lang}>
          {this.options.map(option =>
            <option key={option.ID} value={option.ID}>
              {option.Name}
            </option>)
          }
        </select>
      </div>
    );
  }
}

LangSelect.contextTypes = {
  t: PropTypes.func.isRequired
}

export default connect(state => ({
  lang: state.i18nState.lang,
}))(LangSelect)
